import logo from "./Logo/logo.svg";
import "./App.css";
import "./OneDoseLibrary/ODL.scss";
import React, { useEffect } from "react";
import { Routes, Route, useLocation } from "react-router";
import { useSearchParams, useNavigate } from "react-router-dom";
import QaDashboard from "./QaDashboard";
import Document from "./Document/Document";

const App = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const routerLocation = useLocation();
  const routerNavigate = useNavigate();

  useEffect(() => {
    if (searchParams.has("dc_token")) {
      window.localStorage.setItem("dc_token", searchParams.get("dc_token"));
      searchParams.delete("dc_token");
    }
    if (searchParams.has("dc_app_token")) {
      window.localStorage.setItem("dc_token", searchParams.get("dc_app_token"));
      searchParams.delete("dc_app_token");
    }
    if (searchParams.has("dc_browser")) {
      window.localStorage.setItem("dc_browser", searchParams.get("dc_browser"));
      searchParams.delete("dc_browser");
    }
    setSearchParams(searchParams);
    // navigate(routerLocation.pathname, { replace: true })
    if (routerLocation.pathname.includes("//"))
      window.location.href = routerLocation.pathname.replace(/\/+/g, "/");
    if (routerLocation.pathname === "/" || routerLocation.pathname === "")
      routerNavigate(
        window.location.hostname.includes("internal") ? "admin" : "client"
      );
  }, []);

  const navigate = useNavigate();
  const handleLoginClick = () => {
    navigate("/client/login");
  };

  return (
    <>
      {/* <h1>Welcome to the 1DoseApp!</h1>
    <button onClick={handleLoginClick}>Login</button> */}
      <Routes>
        <Route path="/document/:bucket/:s3path" element={<Document />} />
      </Routes>
    </>
  );
};

export default App;
